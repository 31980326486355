class Header {
    #delayNavItem = 150;
    #delayMobileNav = 150;

    constructor() {
        this.header = document.querySelector('.js-header');

        if (!this.header) {
            return;
        }

        if (window.matchMedia('(max-width: 1023px)').matches) {
            this.#delayNavItem = 0;
        }

        this.sentinel = document.querySelector('.js-sentinel');
        this.headerTop = this.header.querySelector('.js-header__top');
        this.toggle = this.header.querySelector('.js-header__toggle');
        this.switchToggle = this.header.querySelector('.js-switch__toggle');
        this.headerNav = this.header.querySelector('.js-header-nav');
        this.headerNavItems = this.header.querySelectorAll(
            '.js-header-nav__item'
        );
        this.notice = document.querySelector('.notice-bar');
        this.headerBackground = document.querySelector('.js-header-background');
    }

    init() {
        if (!this.header) {
            return;
        }

        if (window.IntersectionObserver) {
            this.initIntersectionObserver();
        } else {
            this.initFallbackScrollListener();
        }

        this.watchNavItems();
        this.watchToggle();
        this.watchBackgroundClick();
    }

    /**
     * Initializes intersection observer and updates header position.
     */
    initIntersectionObserver = () => {
        const observer = new IntersectionObserver((entries) => {
            const position = entries[0].isIntersecting ? 'static' : 'sticky';
            this.header.setAttribute('data-position', position);
        });
        observer.observe(this.sentinel);
    };

    /**
     * Initializes scroll event listener for browsers without IntersectionObserver support.
     */
    initFallbackScrollListener = () => {
        window.addEventListener('scroll', () => {
            const position = window.scrollY > 0 ? 'static' : 'sticky';
            this.header.setAttribute('data-position', position);
        });
    };

    watchNavItems() {
        this.headerNavItems.forEach((item) => {
            item.addEventListener('click', (e) => {
                if (
                    e.target.classList.contains('header-nav__link') &&
                    item.dataset.sublinks > 0
                ) {
                    e.preventDefault();
                    this.toggleNavItem(item);
                    item.querySelector('a')?.focus();
                }
            });
        });
    }

    activateNavItem(el) {
        if (el.dataset.sublinks == 0) {
            return;
        }

        clearTimeout(this.deactivateTimeout); // Clear any pending deactivation timeouts

        el.classList.add('header-nav__item--activating');
        this.headerBackground?.classList.add('header-background--activating');

        this.activateTimeout = setTimeout(() => {
            el.classList.add('header-nav__item--active');
            this.headerBackground?.classList.add('header-background--active');
        }, this.#delayNavItem);

        Astro._search.deactivate();
    }

    deactivateNavItem() {
        clearTimeout(this.activateTimeout); // Clear any pending activation timeouts

        this.headerNavItems.forEach((navItem) =>
            navItem.classList.remove('header-nav__item--active')
        );
        this.headerBackground?.classList.remove('header-background--active');

        this.deactivateTimeout = setTimeout(() => {
            this.headerNavItems.forEach((navItem) =>
                navItem.classList.remove('header-nav__item--activating')
            );
            this.headerBackground?.classList.remove(
                'header-background--activating'
            );
            this.header.removeAttribute('active-item');
        }, this.#delayNavItem);
    }

    toggleNavItem(el) {
        if (el.classList.contains('header-nav__item--active')) {
            this.deactivateNavItem();
        } else {
            const activeItem = this.header.querySelector(
                '.header-nav__item--active'
            );
            if (activeItem) {
                this.deactivateNavItem();
            }
            // Delay the activation to avoid race condition with deactivation
            setTimeout(() => {
                this.activateNavItem(el);
            }, this.#delayNavItem);
        }
    }

    watchToggle() {
        this.toggle?.addEventListener('click', () => {
            this.header.classList.contains('header--activating')
                ? this.deactivate()
                : this.activate();
        });
    }

    activate() {
        this.header.classList.add('header--activating');
        document.documentElement.classList.add('lock-scroll');
        this.activateTimeout = setTimeout(() => {
            this.header.classList.add('header--active');
        }, this.#delayMobileNav);

        Astro._search.deactivate();
    }

    deactivate() {
        document.documentElement.classList.remove('lock-scroll');
        this.header.classList.remove('header--active');
        this.deactivateTimeout = setTimeout(() => {
            this.header.classList.remove('header--activating');
        }, this.#delayMobileNav);
    }

    watchBackgroundClick() {
        this.headerBackground?.addEventListener(
            'click',
            this.deactivateNavItem.bind(this)
        );
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const headerInstance = new Header();

    headerInstance.init();

    Astro._header = headerInstance;
});
